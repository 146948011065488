import { MLoggerLevel } from '@mercadona/core/logger';
import { MPlatformEnvironment } from '@mercadona/core/platform';
import { MTelemetryConfig, MTraces } from '@mercadona/core/telemetry';
import { MEnviroment } from '@mercadona/core/utils/environment';

/**
 * Do not duplicate this file into environment.dev.ts, environment.itg.ts, ...
 * Define here ONLY localhost environment variables.
 *
 * For DEV, ITG, PRE & PRO environments copy this content to the chart files
 * charts/env/values-<env>.yaml
 */
const localEnvironment = {
  production: false,
  logLevel: MLoggerLevel.TRACE,
  iconsMode: false,
  env: 'local' as MPlatformEnvironment,
  telemetryConfig: {
    url: 'https://otelcol-front.dev.gcp.mercadona.com/v1/traces',
    traces: MTraces.DEFAULT
  } as MTelemetryConfig,
  adfs: {
    clientId: '4644a123-4844-452a-9496-11eed7e2e461',
    authority: 'https://fed.itgmercadona.com/adfs',
    knownAuthorities: ['https://fed.itgmercadona.com/adfs'],
    resource: 'api://mercadona/audience/services/ceder/v1',
    scope: ['openid'],
    redirectUri: 'http://localhost:4200/ceder/internal',
    postLogoutRedirectUri: 'http://localhost:4200/ceder',
    restServices: {
      url: 'https://ceder-back.dev.mercadona.com/web-sign/v1',
      resource: 'api://mercadona/audience/services/ceder/v1',
      scope: ['openid'],
      viaFirma: '/signature'
    }
  },
  b2c: {
    clientId: 'abba4af9-1d8b-442e-a338-d1cf568669e2',
    authority: 'https://boteprovb2c.b2clogin.com/boteprovb2c.onmicrosoft.com/B2C_1_CEDER_Login_proveedor/v2.0',
    knownAuthorities: ['https://boteprovb2c.b2clogin.com/boteprovb2c.onmicrosoft.com/B2C_1_CEDER_Login_proveedor'],
    scope: ['abba4af9-1d8b-442e-a338-d1cf568669e2', 'openid'],
    redirectUri: 'http://localhost:4200/ceder/external',
    postLogoutRedirectUri: 'http://localhost:4200/ceder',
    restServices: {
      url: 'https://ceder-back.dev.mercadona.com/web-sign/v1',
      resource: 'https://boteprovb2c.onmicrosoft.com/mercadona/audience/services/ceder/v1',
      scope: ['openid'],
      viaFirma: '/signature'
    }
  }
};

const envInstance = new MEnviroment('ceder-front-main', localEnvironment);

export const environment = envInstance.environment;
