/**
 * Environment helper class.
 * Allows to define a default environment (for local development), but it checks
 * on global configuration (via window object) that can be provided after build.
 */
class MEnviroment {
  constructor(appName, defaultEnvironment) {
    this.appName = appName;
    this.defaultEnvironment = defaultEnvironment;
  }
  get window() {
    return window;
  }
  get windowConfigPath() {
    return `${this.appName}_environment`;
  }
  get environment() {
    return this.window[this.windowConfigPath] ? this.window[this.windowConfigPath] : this.defaultEnvironment;
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { MEnviroment };
